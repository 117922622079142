<template>
  <div>
    <input
      ref="inputFiles"
      type="file"
      :name="name"
      hidden
      @change="uploadFilesHandler"
    />

    <VButton
      text="Импорт XLS"
      icon="import"
      size="sm"
      type="file"
      :loading="isLoading"
      @click.native="$refs.inputFiles.click()"
    />
  </div>
</template>

<script>
import VButton from '@/components/simple/button/VButton'
import { importFiles } from '@/api/request'
import { mixinInputFiles } from '@/mixins/form/mixinInputFiles'
import { mapActions } from 'vuex'

export default {
  name: 'ImportFile',
  components: {
    VButton
  },
  data () {
    return {
      isLoading: false
    }
  },
  mixins: [mixinInputFiles],
  props: {
    url: {
      type: String,
      required: false
    }
  },
  computed: {},
  methods: {
    ...mapActions({
      setAlertSuccess: 'alert/setAlertSuccess',
      setAlertError: 'alert/setAlertError'
    }),
    async addFiles (file) {
      try {
        this.isLoading = true
        const params = {
          file: file[0]
        }
        await importFiles(this.url, params)
        await this.setAlertSuccess('Файл успешно импортирован')
      } catch (e) {
        await this.setAlertError(e.response.data.errors.file[0])
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
