function mapPositionReserveTableData (item) {
  if (item?.children_categories?.length) {
    item.children_categories = item.children_categories.map(mapPositionReserveTableData)
  }

  if (item?.positions?.length) {
    item.positions = item.positions.map(mapPositionReserveTableData)
  }

  if (item?.positions?.length || item?.children_categories?.length) {
    return item
  }

  return ({
    ...item,
    free_reserves: (item.amount - item.amount_reserved) || 0,
    orders: item.orders || []
  })
}

export {
  mapPositionReserveTableData
}
