const ERROR_CODES = {
  EMAIL_NOT_FOUND: 'Пользователь с таким email не был найден',
  INVALID_PASSWORD: 'Пароль неверный',
  'Credentials not match': 'Пользователь с таким email не существует или неверный пароль!',
  'Слишком большое значение': 'Количество изделий в партии не должно превышать количество изделий в позиции',
  auth: 'Пожалуйста войдите в систему'
}

export function error (code) {
  return ERROR_CODES[code] ? ERROR_CODES[code] : 'Неизвестная ошибка'
}
