import { mixinInputModel } from '@/mixins/form/mixinInputModel'
import { spliceBy } from '@/utils/array/reactive'

export const mixinInputFiles = {
  mixins: [mixinInputModel],
  props: {
    value: {
      type: [Array, FileList, null],
      default: () => []
    },
    label: {
      type: String,
      required: false
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: true
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    countFiles () {
      return this.modelValue?.length || 0
    }
  },
  methods: {
    removeFileHandler ({ id, index }) {
      if (id) {
        spliceBy(id, this.modelValue)
        this.$emit('onRemoveFile', id)
        return
      }

      this.modelValue.splice(index, 1)
    },

    uploadFilesHandler () {
      this.addFiles(this.$refs.inputFiles.files)
    },

    addFiles (files) {
      if (this.countFiles) {
        return this.modelValue.push(...files)
      }

      this.modelValue = [...files]
    }
  }
}
